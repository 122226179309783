.payment_details_container {
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-start;
}

.payment_details_container .apmt_details_container {
  padding: 1rem 0;
}

.apmt_details {
  color: #4f566b;
  font-weight: 400;
  font-size: 20px;
}

.apmt_details_dark {
  font-weight: 600;
}

.details_heading {
  padding: 0.5rem 0;
}

.details_heading_text {
  font-size: 18px;
  color: #4f566b;
}

.payment_details_box {
  border: 1px solid #139696;
  border-radius: 8px;
  height: 280px;
  width: 100%;
}

.payment_details_box_content {
  padding: 1.5rem 1.5rem 1rem;
}

.payment_details_row {
  /* text-align: justify !important; */
}

.box_subheading {
  font-size: 19px;
  font-weight: 600;
  line-height: 25px;
  padding-bottom: 12px;
}

.superscript {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.box_amount {
  font-size: 18px;
  line-height: 30px;
  color: #697386;
  padding-bottom: 6px;
}

.box_content_format {
  display: flex;
}

.payment_options_container {
  padding-top: 1rem;
}

.pay_card_button_container {
  padding: 1rem 0 1rem 0;
}

.pay_office_button_container {
  padding-bottom: 1rem;
}

.pay_office_button {
  width: 100%;
}

.pay_card_button {
  width: 100%;
  color: white !important;
}

.explanations_container {
  padding-top: 1rem;
}

.explanation_text {
  padding-bottom: 10px;
  color: rgb(113, 111, 111);
}
